import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import "../../assets/scss/datatables.scss";


const LeagueTeamRatings = ({ countrySlug, leagueSlug, competitionSlug }) => {
    
    const [teams, setTeams] = useState([]);
    
    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await axios.get(`/api/${countrySlug}/${leagueSlug}/${competitionSlug}/team-ratings`);
                setTeams(response.data);
            } catch (error) {
                console.error("Fehler beim Abrufen der Teams:", error);
            }
        };
        
        fetchTeams();
    }, [countrySlug, leagueSlug, competitionSlug]);

    const data = {
        columns: [
            { label: "Team", field: "team", sort: "asc", width: 250 },
            { label: "Tabellenposition", field: "table_position", sort: "asc", width: 80 },
            { label: "WTB", field: "wtb", sort: "desc", width: 80 },
            { label: "OTV+1", field: "otv_1", sort: "desc", width: 80 },
            { label: "OTV-S", field: "otv_s", sort: "desc", width: 80 },
            { label: "OTV-xG", field: "otv_xg", sort: "desc", width: 80 },
            { label: "Game Form", field: "gameform", sort: "desc", width: 80 },
            { label: "Power Rating", field: "powerrating", sort: "desc", width: 80 },
            { label: "Penetration Plus", field: "penetrationplus", sort: "desc", width: 80 }
        ],
        rows: teams.map((team) => ({
            team: team.team.name,
            table_position: team.table_position,
            wtb: formatTeamData(team, 'wtb') || 0,
            otv_1: formatTeamData(team, 'otv_1') || 0,
            otv_s: formatTeamData(team, 'otv_s') || 0,
            otv_xg: formatTeamData(team, 'otv_xg') || 0,
            gameform: formatTeamData(team, 'gameform') || 0,
            powerrating: formatTeamData(team, 'powerrating') || 0,
            penetrationplus: formatTeamData(team, 'penetrationplus') || 0
        }))
    };

    function formatTeamData(team, type) {
        let data;
        let value;

        if (type.startsWith('wtb') || type.startsWith('otv')) {
            data = team.next_match.zufallsindikatoren;
            value = data[`z_score_team_${team.team.id === team.next_match.hometeam.id ? 'a' : 'b'}_${type}_prematch`];
        } else {
            data = team.next_match.teamstaerken;
            value = data[`z_score_team_${team.team.id === team.next_match.hometeam.id ? 'a' : 'b'}_${type}_prematch`];
        }

        return value != null ? parseFloat(value.toFixed(3)) : null; // Rückgabe als float anstatt als String
    }
    
    return (
        <React.Fragment>
            <h2>Team Ratings</h2>
            <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns
                entries={teams.length}
                paging={false}
            />
        </React.Fragment>
    );
};

export default LeagueTeamRatings;
