import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import MatchesOverview from './MatchesOverview';

const MatchesByDate = () => {
  const { date } = useParams(); // Extrahiere das Datum aus der URL
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axios.get(`/api/matches/by-date/?date=${date}`);
        setMatches(response.data);
      } catch (error) {
        console.error('Fehler beim Laden der Matches:', error);
      }
    };

    fetchMatches();
  }, [date]);

  return (
    <MatchesOverview matches={matches} />
  );
};

export default MatchesByDate;
