import React from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardText, Badge, Progress } from 'reactstrap';

const MatchesOverview = ({ matches }) => {
    const convertUnixToTime = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Unix-Zeitstempel in Millisekunden umwandeln
        return date.toLocaleTimeString("de-DE", { 
            hour: "2-digit", 
            minute: "2-digit",
            second: undefined // Sekunden nicht anzeigen
        });
    };

    const convertUnixToDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Unix-Zeitstempel in Millisekunden umwandeln
        return date.toLocaleDateString("de-DE", {
            weekday: "short",
            // year: "numeric",
            month: "long",
            day: "numeric"
        });
    };

    const getBadgeColor = (wert) => {
      if (wert < -1) return "danger";
      else if (wert > 1) return "success";
      else if (wert == null) return "secondary"; // Wert ist null (keine Daten vorhanden
      else return "warning"; // Alles zwischen -1 und +1, außer 0
    };

  return (
    <React.Fragment>
      {matches.map((match) => (
        <Card key={match.id} className="mb-4">
          <CardBody>
            <Row className="d-flex align-items-center">
              {/* Erste Spalte: Uhrzeit */}
              <Col xs="1" className="text-center">
                <strong>{convertUnixToDate(match.date_unix)}</strong><br />
                <strong>{convertUnixToTime(match.date_unix)} Uhr</strong>
              </Col>

              {/* Zweite Spalte: Heimteam + Form, Ergebnis, Auswärtsteam + Form, nebeneinander */}
              <Col xs="8">

                <Row className="align-items-center mb-3">
                  {/* Heimteam + Form */}
                  <Col xs="5"  className="text-end">
                    <h4>{match.hometeam.name}</h4>
                  </Col>

                  {/* Ergebnis */}
                  <Col xs="2" className="text-center">
                    <h4>{match.status === "complete" ? match.homeGoalCount + " - " + match.awayGoalCount : " - "}</h4>
                  </Col>

                  {/* Auswärtsteam + Form */}
                  <Col xs="5" className="text-start">
                    <h4>{match.awayteam.name}</h4>
                  </Col>
                </Row>

                {/* Ratings, alle nebeneinander */}
                <div className="d-flex align-items-center justify-content-center">
                    <h5><Badge className="p-2 mx-2" color={getBadgeColor(match.zufallsindikatoren.z_score_total_wtb_prematch)}>
                      {match.zufallsindikatoren.z_score_total_wtb_prematch != null
                        ? match.zufallsindikatoren.z_score_total_wtb_prematch.toFixed(3) 
                        : "-"}
                    </Badge></h5>
                    <h5><Badge className="p-2 mx-2" color={getBadgeColor(match.zufallsindikatoren.z_score_total_otv_1_prematch)}>
                      {match.zufallsindikatoren.z_score_total_otv_1_prematch != null
                        ? match.zufallsindikatoren.z_score_total_otv_1_prematch.toFixed(3) 
                        : "-"}
                    </Badge></h5>
                    <h5><Badge className="p-2 mx-2" color={getBadgeColor(match.zufallsindikatoren.z_score_total_otv_s_prematch)}>
                      {match.zufallsindikatoren.z_score_total_otv_s_prematch != null
                        ? match.zufallsindikatoren.z_score_total_otv_s_prematch.toFixed(3) 
                        : "-"}
                    </Badge></h5>
                    <h5><Badge className="p-2 mx-2" color={getBadgeColor(match.zufallsindikatoren.z_score_total_otv_xg_prematch)}>
                      {match.zufallsindikatoren.z_score_total_otv_xg_prematch != null
                        ? match.zufallsindikatoren.z_score_total_otv_xg_prematch.toFixed(3) 
                        : "-"}
                    </Badge></h5>
                    <h5><Badge className="p-2 mx-2" color={getBadgeColor(match.teamstaerken.z_score_total_powerrating_prematch)}>
                      {match.teamstaerken.z_score_total_powerrating_prematch != null
                        ? match.teamstaerken.z_score_total_powerrating_prematch.toFixed(3) 
                        : "-"}
                    </Badge></h5>
                    <h5><Badge className="p-2 mx-2" color={getBadgeColor(match.teamstaerken.z_score_total_gameform_prematch)}>
                      {match.teamstaerken.z_score_total_gameform_prematch != null
                        ? match.teamstaerken.z_score_total_gameform_prematch.toFixed(3) 
                        : "-"}
                    </Badge></h5>
                    <h5><Badge className="p-2 mx-2" color={getBadgeColor(match.teamstaerken.z_score_total_penetrationplus_prematch)}>
                      {match.teamstaerken.z_score_total_penetrationplus_prematch != null
                        ? match.teamstaerken.z_score_total_penetrationplus_prematch.toFixed(3) 
                        : "-"}
                    </Badge></h5>
                </div>

              </Col>

              {/* Vierte Spalte: Wahrscheinlichkeiten */}
              <Col xs="3">
                <div><strong>Wahrscheinlichkeiten: (Aktuell Fake-Werte) </strong></div>
                <Progress multi className="mt-2">
                  <Progress bar color="success" value="55">55%</Progress>
                  <Progress bar color="warning" value="25">25%</Progress>
                  <Progress bar color="danger" value="20">20%</Progress>
                </Progress>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ))}
    </React.Fragment>
  );
};

export default MatchesOverview;
