import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import MatchesOverview from './MatchesOverview';

const CompetitionMatches = (props) => {
    const { leagueSlug, competitionSlug } = useParams();

    document.title = "Competition Matches | Soccer-Ratings";
    
    const [matches, setMatches] = useState([]);
    
    useEffect(() => {
        const fetchMatches = async () => {
            try {
                const response = await axios.get(`/api/competition/${leagueSlug}/${competitionSlug}/matches`);
                console.log(response.data);
                setMatches(response.data);
            } catch (error) {
                console.error("Fehler beim Abrufen der Matches:", error);
            }
        };
        
        fetchMatches();
    }, [leagueSlug, competitionSlug]);

  return (
    <MatchesOverview matches={matches} />
  );
};

export default CompetitionMatches;
