import React from "react"
import { Navigate } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Teams Next Match
import LeagueTeamRatings from "../components/leagues/LeagueTeamRatings"
import CompetitionMatches from "../components/matches/CompetitionMatches"
import MatchesByDate from "../components/matches/MatchesByDate"
import UpcomingMatches from "../components/matches/UpcomingMatches"

import LeagueDetails from "../components/leagues/LeagueDetails"

//Extra Pages
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";

const MyRoutes = [
  { path: "/", component: <Dashboard /> },

  { path: "/upcoming-matches", component: <UpcomingMatches /> },

  { path: "/competition/:leagueSlug/:competitionSlug/matches", component: <CompetitionMatches /> },

  { path: "/matches/:date", component:<MatchesByDate />},

  { path: "/:countrySlug/:leagueSlug", component: <LeagueDetails />},
  { path: "/:countrySlug/:leagueSlug/:competitionSlug/team-ratings", component: <LeagueTeamRatings /> },

  { path: "/pages-blank", component: <PagesBlank /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
]

export { MyRoutes }