import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CompetitionNextMatches from '../matches/CompetitionNextMatches';
import CompetitionPreviousMatches from '../matches/CompetitionPreviousMatches';
import LeagueTeamRatings from './LeagueTeamRatings';

const LeagueDetails = () => {
  const { countrySlug, leagueSlug } = useParams();
  const [leagueDetails, setLeagueDetails] = useState(null);
  
  useEffect(() => {
    const fetchLeagueDetails = async () => {
      try {
        const response = await axios.get(`/api/${countrySlug}/${leagueSlug}`);
        setLeagueDetails(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Liga-Daten:', error);
      }
    };

    fetchLeagueDetails();
  }, [countrySlug, leagueSlug]);

  if (!leagueDetails) {
    return <div>Laden...</div>;
  }

  // Stelle sicher, dass competitionSlug korrekt ist
  const competitionSlug = leagueDetails.current_season?.slug; // Beispielannahme

  return (
    <React.Fragment>
      <h1>{leagueDetails.name}</h1>
      <CompetitionNextMatches
        countrySlug={countrySlug}
        leagueSlug={leagueSlug}
        competitionSlug={competitionSlug}
      />
      <CompetitionPreviousMatches
        countrySlug={countrySlug}
        leagueSlug={leagueSlug}
        competitionSlug={competitionSlug} 
      />
      <LeagueTeamRatings
        countrySlug={countrySlug}
        leagueSlug={leagueSlug}
        competitionSlug={competitionSlug}
      />
    </React.Fragment>
  );
};

export default LeagueDetails;