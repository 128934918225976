import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Card, CardBody, CardTitle, Badge } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import MatchesOverview from "./MatchesOverview";

const MatchesToday = () => {
    
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0]; // Heutiges Datum im Format "YYYY-MM-DD"
        const loadData = async () => {
            try {
                const response = await axios.get(`/api/matches/by-date/?date=${today}`);
                setMatches(response.data);
            } catch (error) {
                console.error('Fehler beim Abrufen der heutigen Spiele:', error);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const convertUnixToTime = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Unix-Zeitstempel in Millisekunden umwandeln
        return date.toLocaleTimeString("de-DE", { 
            hour: "2-digit", 
            minute: "2-digit",
            second: undefined // Sekunden nicht anzeigen
        });
    };

    const getBadgeColor = (wert) => {
        if (wert < -1) return "danger";
        else if (wert > 1) return "success";
        else return "warning"; // Alles zwischen -1 und +1, außer 0
    };

    if (loading) return <p>Lade Daten...</p>;

    return (
        <React.Fragment>
            <MatchesOverview matches={matches} />
            
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">Heutige Matches</CardTitle>

                            <div className="table-rep-plugin">
                                <div className="table-responsive">
                                    <Table className="table align-middle text-center">
                                        {/* Kopfzeile der Tabelle */}
                                        <Thead>
                                            <Tr>
                                                <Th>Uhrzeit</Th>
                                                <Th>
                                                    <div className="d-flex justify-content-between">
                                                        <span>Heim</span>
                                                        <span>Form</span>
                                                    </div>
                                                </Th>
                                                <Th>Status</Th>
                                                <Th>
                                                    <div className="d-flex justify-content-between">
                                                        <span>Form</span>
                                                        <span>Auswärts</span>
                                                    </div>
                                                </Th>
                                                <Th>Performance</Th>
                                                <Th>Teamstärke</Th>
                                            </Tr>
                                        </Thead>
                                        {/* Tabellenkörper mit den Daten */}
                                        <Tbody>
                                        {matches.map((match) => {
                                              // Mittelwert der Zufallsindikatoren berechnen
                                              const zufallsindikatoren = [
                                                match.zufallsindikatoren.stabw_total_wtb_prematch,
                                                match.zufallsindikatoren.stabw_total_otv_1_prematch,
                                                match.zufallsindikatoren.stabw_total_otv_s_prematch,
                                                match.zufallsindikatoren.stabw_total_otv_xg_prematch,
                                              ];
                                              const mittelwertZufallsindikatoren = zufallsindikatoren.reduce((sum, wert) => sum + wert, 0) / zufallsindikatoren.length;
                                              const gerundeterMittelwertZufallsindikatoren = (Math.round(mittelwertZufallsindikatoren * 1000) / 1000);
                                              const badgeColorZufallsindikatoren = getBadgeColor(gerundeterMittelwertZufallsindikatoren);

                                              const teamstaerken = [
                                                match.teamstaerken.stabw_total_powerrating_prematch,
                                                match.teamstaerken.stabw_total_gameform_prematch,
                                                match.teamstaerken.stabw_total_penetrationplus_prematch,
                                              ];
                                                const mittelwertTeamstaerken = teamstaerken.reduce((sum, wert) => sum + wert, 0) / teamstaerken.length;
                                                const gerundeterMittelwertTeamstaerken = (Math.round(mittelwertTeamstaerken * 1000) / 1000);
                                                const badgeColorTeamstaerken = getBadgeColor(gerundeterMittelwertTeamstaerken);
                                              
                                              return (
                                                <Tr key={match.id}>
                                                    <Td>{convertUnixToTime(match.date_unix)}</Td>
                                                    <Td>
                                                        <div className="d-flex justify-content-between">
                                                            <span>{match.hometeam.name}</span>
                                                            <span>{match.teamstaerken.stabw_team_a_gameform_prematch}</span>
                                                        </div>
                                                    </Td>
                                                    <Td>
                                                        {match.status === "complete" ? (
                                                            <>
                                                            {`${match.homeGoalCount}:${match.awayGoalCount}`}
                                                            <br />
                                                            FT
                                                            </>
                                                        ) : (
                                                            match.status
                                                        )}
                                                    </Td>

                                                    <Td>
                                                        <div className="d-flex justify-content-between">
                                                            <span>{match.teamstaerken.stabw_team_b_gameform_prematch}</span>
                                                            <span>{match.awayteam.name}</span>
                                                        </div>
                                                    </Td>
                                                    <Td><h5><Badge color={badgeColorZufallsindikatoren} className={`bg-${badgeColorZufallsindikatoren}`}>{gerundeterMittelwertZufallsindikatoren}</Badge></h5></Td>
                                                    <Td><h5><Badge color={badgeColorTeamstaerken} className={`bg-${badgeColorTeamstaerken}`}>{gerundeterMittelwertTeamstaerken}</Badge></h5></Td>
                                                    {/* Weitere Zellen, falls benötigt */}
                                                </Tr>
                                              );
                                            })}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    );
};

export default MatchesToday;