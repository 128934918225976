import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MatchesOverview from './MatchesOverview';

const CompetitionPreviousMatches = ({ countrySlug, leagueSlug, competitionSlug }) => {
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const url = `/api/${countrySlug}/${leagueSlug}/${competitionSlug}/previous-matches`;
        const response = await axios.get(url);
        setMatches(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Fehler beim Abrufen der vergangenen Spiele:', error);
        setIsLoading(false);
      }
    };

    if (competitionSlug) {
      fetchMatches();
    }
  }, [countrySlug, leagueSlug, competitionSlug]);

  if (isLoading) {
    return <div>Laden der vergangenen Spiele...</div>;
  }

  return (
    <React.Fragment>
      <h2>Vergangene Spiele</h2>
      <MatchesOverview matches={matches} />
    </React.Fragment>
  );
};

export default CompetitionPreviousMatches;
